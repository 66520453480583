<template>
  <div class="container">
    <div class="columns is-centered">
      <div class="column">
        <div class="content">
          <ValidationObserver v-slot="{ errors }" tag="div">
            <input-form
              ref="form"
              :errors="errors"
              name="BankAccountForm"
              @submit.prevent="handleSubmit"
            >
              <br />
              <h1>Bank Account Information</h1>
              <callout type="info">
                Note: The account Nickname field can be used to give this bank
                account a unique name so that you can easily associate it with
                each property you own so that you can easily associate it with
                each property you own
              </callout>
              <vee-textbox
                v-model="Nickname"
                name="input-nickname"
                required
                error="This field is required"
                rules="required"
                placeholder="Account Nickname"
                type="text"
              />
              <br />
              <vee-textbox
                v-model="OwnerOrBusinessName"
                name="input-owner-or-business-name"
                required
                error="This field is required"
                rules="required"
                placeholder="Owner/Business Name on Account"
                type="text"
              />
              <br />
              <vee-textbox
                v-model="BankName"
                name="input-bank-name"
                required
                error="This field is required"
                rules="required"
                placeholder="Bank Name"
                type="text"
              />
              <h3>Address On Bank Account</h3>
              <callout type="info"
                >Please enter the address associated with this bank account
                below. We recommend you copy the address from your bank
                statement or app. Any typos or mismatches will cause your loan
                draw wires to be rejected by the bank.</callout
              >
              <div class="columns">
                <div class="column">
                  <vee-textbox
                    v-model="AddressOnBankAccountStreet1"
                    name="input-address-on-bank-account-street-1"
                    required
                    error="This field is required"
                    rules="required"
                    placeholder="Street Address"
                    type="text"
                  />
                </div>
                <div class="column">
                  <vee-textbox
                    v-model="AddressOnBankAccountStreet2"
                    name="input-address-on-bank-account-street-2"
                    placeholder="Apt / Unit"
                    type="text"
                  />
                </div>
                <div class="column">
                  <vee-textbox
                    v-model="AddressOnBankAccountCity"
                    name="input-address-on-bank-account-city"
                    required
                    error="This field is required"
                    rules="required"
                    placeholder="City"
                    type="text"
                  />
                </div>
              </div>
              <div class="columns">
                <div class="column">
                  <vee-dropdown
                    v-model="AddressOnBankAccountState"
                    name="input-address-on-bank-account-state"
                    required
                    error="This field is required"
                    rules="required"
                    label="State"
                    :options="States"
                    placeholder="-- Select a State --"
                    type="text"
                  />
                </div>
                <div class="column">
                  <vee-textbox
                    v-model="AddressOnBankAccountZip"
                    name="input-address-on-bank-account-zip"
                    required
                    error="This field is required"
                    rules="required"
                    placeholder="Zip Code"
                    type="text"
                  />
                </div>
                <div class="column">
                  <vee-dropdown
                    v-model="AddressOnBankAccountCountry"
                    name="input-address-on-bank-account-country"
                    label="Country"
                    :options="Countries"
                    placeholder="-- Select a Country --"
                    mode="eager"
                  />
                </div>
              </div>
              <br />
              <div style="margin-left: 1%;">
                <h5>
                  Upload a document with the account holder’s name, account
                  number, and routing number:
                  <b style="color:red">(required)</b>
                </h5>
                <ul id="upload-list-account-documents">
                  <li v-for="file in AccountDocuments" :key="file.id">
                    <span>{{ file.name }} - </span>
                    <span v-if="file.error" class="is-love-park-red">
                      Something went wrong - {{ file.errorMessage }}
                    </span>

                    <span v-else-if="file.success" class="is-phanatic-green">
                      Upload Successful. Please select another document to
                      upload if you have multiple documents.
                    </span>
                    <span v-else-if="file.uploading" class="is-flyers-orange">
                      Uploading Document...</span
                    >
                  </li>
                </ul>
                <file-upload
                  ref="upload"
                  v-model="AccountDocuments"
                  :input-id="`file-upload-account-document`"
                  extensions="gif,jpg,jpeg,png,webp,pdf"
                  accept="image/png,image/gif,image/jpeg,image/webp,application/pdf"
                  :size="1024 * 1024 * 4.5"
                  @input-filter="inputFilter"
                >
                  <button class="button is-primary">
                    <i class="fa fa-plus" />
                    Select File to Upload
                  </button>
                </file-upload>
              </div>
              <br />
              <vee-textbox
                v-model="AccountNumber"
                name="input-account-number"
                required
                error="This field is required"
                rules="required|numeric"
                placeholder="Account Number"
                vid="input-account-number"
                type="text"
              />
              <br />
              <vee-textbox
                v-model="ConfirmAccountNumber"
                name="input-confirm-account-number"
                required
                error="This field is required"
                rules="required|numeric|account_confirmed:input-account-number"
                placeholder="Confirm Account Number"
                type="text"
              />
              <br />
              <vee-textbox
                v-model="RoutingNumber"
                name="input-routing-number"
                required
                error="This field is required"
                rules="required|numeric"
                placeholder="Routing Number"
                type="text"
              />
              <br />
              <vee-textbox
                v-model="ConfirmRoutingNumber"
                name="input-confirm-routing-number"
                required
                error="This field is required"
                rules="required|numeric|routing_confirmed:input-routing-number"
                placeholder="Confirm Routing Number"
                type="text"
              />
              <div>
                <div class="column is-2 is-offset-10">
                  <button
                    type="button"
                    :disabled="
                      SubmitStatus.Value === 'PENDING' ||
                        SubmitStatus.Value === 'OK'
                    "
                    class="button is-primary"
                    @click="handleSubmit"
                  >
                    Submit
                  </button>
                </div>
              </div>
              <div class="column">
                <div v-if="SubmitStatus.Value === SubmitStatus.Options.PENDING">
                  Submitting...
                </div>
                <div v-if="SubmitStatus.Value === SubmitStatus.Options.OK">
                  OK
                </div>
                <div v-if="SubmitStatus.Value === SubmitStatus.Options.ERROR">
                  Error
                </div>
              </div>
            </input-form>
          </ValidationObserver>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import options from "@/utilities/options.js";
import FileUpload from "vue-upload-component";
import axios from "axios";
import { dataObjectToXml } from "@/utilities/functions.js";
import buildBankSubmissionDataObject from "@/utilities/bank-account-submission-object.js";
import { getBase64, resizeImage } from "@/utilities/functions";
export default {
  name: "BankAccountForm",
  components: { FileUpload },
  mixins: [options],
  props: {
    recordId: { default: null, type: String }
  },
  data() {
    return {
      Nickname: null,
      OwnerOrBusinessName: null,
      AccountDocuments: [],
      BankName: null,
      AccountNumber: null,
      ConfirmAccountNumber: null,
      RoutingNumber: null,
      ConfirmRoutingNumber: null,
      AddressOnBankAccountStreet1: null,
      AddressOnBankAccountStreet2: null,
      AddressOnBankAccountCity: null,
      AddressOnBankAccountState: null,
      AddressOnBankAccountZip: null,
      AddressOnBankAccountCountry: "United States"
    };
  },
  methods: {
    inputFilter(newFile, oldFile, prevent) {
      if (newFile && !oldFile) {
        // Before adding a file
        // Filter system files or hide files
        if (/(\/|^)(Thumbs\.db|desktop\.ini|\..+)$/.test(newFile.name)) {
          return prevent();
        }
        // Filter php html js file
        if (/\.(php5?|html?|jsx?|exe?)$/i.test(newFile.name)) {
          return prevent();
        }
      }
    },
    async handleSubmit() {
      var vData = this;
      var uploadData = this.AccountDocuments;
      vData.SubmitStatus.Value = vData.SubmitStatus.Options.PENDING;
      const isInputValid = await vData.$refs.form.$parent.validate();
      if (isInputValid) {
        if (uploadData.length) {
          let FileIdx = uploadData.length - 1;
          var fileObj = uploadData[FileIdx];
          fileObj.uploading = true;
          fileObj.error = false;
          fileObj.errorMessage = "";
          let file = fileObj.file;
          if (fileObj.type.match(/image.*/)) {
            file = await resizeImage({ file: file, maxSize: 1920 });
          }
          if (file.size >= 1024 * 1024 * 4.5) {
            fileObj.error = true;
            fileObj.errorMessage =
              "File is too large, please reduce your file's size and try again.";
          } else {
            const base64File = await getBase64(file);
            const dataObject = buildBankSubmissionDataObject(
              vData,
              base64File,
              fileObj.name
            );
            console.log(dataObject);
            const xmlDataObject = dataObjectToXml(dataObject);
            console.log(xmlDataObject);
            const requestObject = {
              table: "bs8ywbuje",
              action: "API_AddRecord",
              data: xmlDataObject
            };

            axios
              .post(
                "https://djql8jp3sb.execute-api.us-east-1.amazonaws.com/Rental-Improvement-Fund-QuickBase-Request-Proxy",
                requestObject
              )
              .then(() => {
                fileObj.uploading = false;
                fileObj.success = true;
                vData.SubmitStatus.Value = vData.SubmitStatus.Options.OK;
                vData.$router.push({
                  name: "BankAccountSuccess"
                });
              })
              .catch(() => {
                fileObj.error = true;
                fileObj.errorMessage =
                  "File is too large, please reduce your file's size and try again.";
              });
          }
        } else {
          vData.SubmitStatus.Value = vData.SubmitStatus.Options.ERROR;
        }
      }
    }
  }
};
</script>
