import DataObjectField from "@/utilities/classes/data-object-field";

const buildBankSubmissionDataObject = (vData, base64File, FileName) => {
  const fields = [
    new DataObjectField(vData.BankName, 6),
    new DataObjectField(vData.OwnerOrBusinessName, 7),
    new DataObjectField(vData.RoutingNumber, 8),
    new DataObjectField(vData.AccountNumber, 9),
    new DataObjectField(vData.recordId, 40),
    new DataObjectField(vData.AddressOnBankAccountStreet1, 64),
    new DataObjectField(vData.AddressOnBankAccountStreet2, 65),
    new DataObjectField(vData.AddressOnBankAccountCity, 66),
    new DataObjectField(vData.AddressOnBankAccountState, 67),
    new DataObjectField(vData.AddressOnBankAccountZip, 68),
    new DataObjectField(vData.AddressOnBankAccountCountry, 69),
    {
      _fid: "10",
      _filename: FileName,
      __text: base64File
    }
  ];

  const dataObject = {
    qdbapi: {
      udata: "_DBID_BANK_ACCOUNT",
      apptoken: "d8phrhgbq4mmfwc3rkhnddciskd3",
      field: fields
    }
  };
  return dataObject;
};

export default buildBankSubmissionDataObject;
