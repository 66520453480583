import DataObjectField from "@/utilities/classes/data-object-field";

const buildEduSubmissionDataObject = vData => {
  const fields = [
    new DataObjectField(vData.FirstName, 15),
    new DataObjectField(vData.LastName, 16),
    new DataObjectField(vData.EmailAddress, 6),
    new DataObjectField(vData.quickbaseRecordID, 8)
  ];

  const dataObject = {
    qdbapi: {
      udata: "_DBID_EDU_SESSION",
      apptoken: "d8phrhgbq4mmfwc3rkhnddciskd3",
      field: fields
    }
  };
  return dataObject;
};

export default buildEduSubmissionDataObject;
