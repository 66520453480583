<template>
  <div class="container">
    <div class="columns is-centered">
      <div class="column">
        <div class="content">
          <div class="column">
            <img
              style="margin-left: 30%;"
              src="../assets/PHDC-rental-repair-logo-x-450.jpg"
            />
            <br />
            <label
              ><em
                ><b
                  >Thank you for for your interest in the Rental Improvement
                  Fund. The first step is to create a Landlord Profile, below.
                  Afterwards, you will receive a confirmation email that
                  includes a link to submit a Rental Improvement Fund loan
                  application. If have any technical issues with the application
                  process, please email
                  <a href="mailto:RentalImprove@phila.gov"
                    >RentalImprove@phila.gov</a
                  ></b
                ></em
              ></label
            >
          </div>
          <ValidationObserver v-slot="{ errors }" tag="div">
            <input-form
              ref="form"
              :errors="errors"
              name="projectDocumentsFrm"
              @submit.prevent="toPropertyPage"
            >
              <div class="columns">
                <div class="column">
                  <h2><b> Demographics</b></h2>
                  <vee-dropdown
                    id="race"
                    v-model="race"
                    mode="eager"
                    name="dropdown-race"
                    :options="raceOptions"
                    placeholder="What best describes your race?"
                    rules="required"
                    required
                    error="This field is required"
                  />
                </div>
              </div>

              <div class="columns">
                <div class="column">
                  <vee-dropdown
                    id="ethnicity"
                    v-model="ethnicity"
                    mode="eager"
                    name="dropdown-ethnicity"
                    :options="ethnicityOptions"
                    placeholder="What best describes your ethnicity?"
                    rules="required"
                    required
                    error="This field is required"
                  />
                </div>
              </div>

              <div class="columns">
                <div class="column">
                  <vee-dropdown
                    id="gender"
                    v-model="gender"
                    mode="eager"
                    name="dropdown-race"
                    :options="genderOptions"
                    placeholder="What best describes your gender identity?"
                    rules="required"
                    required
                    error="This field is required"
                  />
                </div>
              </div>

              <div class="columns">
                <div class="column">
                  <vee-dropdown
                    id="minority"
                    v-model="minority"
                    mode="eager"
                    name="dropdown-minority"
                    :options="radioOptions.YesNo"
                    placeholder="Are you registered as a minority, woman, or disabled-owned enterprise (M/W/DSBE)??"
                    rules="required"
                    required
                    error="This field is required"
                  />
                </div>
              </div>
              <div class="column is-2 is-offset-10">
                <button
                  type="button"
                  class="button is-primary"
                  @click="toPropertyPage"
                >
                  Enter
                </button>
              </div>
            </input-form>
          </ValidationObserver>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import options from "@/utilities/options.js";

export default {
  name: "Demographics",
  mixins: [options],
  props: {
    fName: { type: String, default: null },
    lName: { type: String, default: null },

    street1: { type: String, default: null },
    street2: { type: String, default: null },
    city: { type: String, default: null },
    state: { type: String, default: null },
    zipCode: { type: String, default: null },

    email: { type: String, default: null },
    phoneNum: { type: String, default: null },
    phoneExt: { type: String, default: null },
    cityEmployee: { type: String, default: null },
    RIFKnowledge: { type: String, default: null },
    RIFKnowledgeOther: { type: String, default: null },
    dateOfBirth: { type: String, default: null },
    preferredContact: { type: String, default: null }
  },
  data() {
    return {
      race: null,
      ethnicity: null,
      gender: null,
      minority: null
    };
  },
  methods: {
    async toPropertyPage() {
      const isInputValid = await this.$refs.form.$parent.validate();
      if (isInputValid) {
        this.$router.push({
          name: "PropertyInformation",
          params: {
            fName: this.fName,
            lName: this.lName,

            street1: this.street1,
            street2: this.street2,
            city: this.city,
            state: this.state,
            zipCode: this.zipCode,

            email: this.email,
            phoneNum: this.phoneNum,
            phoneExt: this.phoneExt,
            cityEmployee: this.cityEmployee,
            RIFKnowledge: this.RIFKnowledge,
            RIFKnowledgeOther: this.RIFKnowledgeOther,
            comments: this.comments,
            dateOfBirth: this.dateOfBirth,
            preferredContact: this.preferredContact,
            race: this.race,
            ethnicity: this.ethnicity,
            gender: this.gender,
            minority: this.minority
          }
        });
      }
    }
  }
};
</script>
