const buildStatusCheckObject = (queryData, queryData2, uData) => {
  const dataObject = {
    qdbapi: {
      udata: uData,
      apptoken: "d8phrhgbq4mmfwc3rkhnddciskd3",
      QueryString: queryData,
      QueryString2: queryData2,
      fmt: "structured"
    }
  };
  if (queryData) {
    dataObject.qdbapi.query = `{'6'.EX.'${queryData}'}AND{'268'.EX.'${queryData2}'}`;
  }
  return dataObject;
};

export default buildStatusCheckObject;
