<template>
  <div class="container">
    <div class="columns is-centered">
      <div class="column">
        <div class="content">
          <div class="column">
            <img
              style="margin-left: 30%;"
              src="../assets/PHDC-rental-repair-logo-x-450.jpg"
            />
            <br />
            <label
              ><em
                ><b
                  >Thank you for for your interest in the Rental Improvement
                  Fund. The first step is to create a Landlord Profile, below.
                  Afterwards, you will receive a confirmation email that
                  includes a link to submit a Rental Improvement Fund loan
                  application. If have any technical issues with the application
                  process, please email
                  <a href="mailto:RentalImprove@phila.gov"
                    >RentalImprove@phila.gov</a
                  ></b
                ></em
              ></label
            >
          </div>
          <ValidationObserver v-slot="{ errors }" tag="div">
            <input-form
              ref="form"
              :errors="errors"
              name="PropertyInformationForm"
              @submit.prevent="toAffidavitPage"
            >
              <div class="columns">
                <div class="column">
                  <vee-dropdown
                    id="numOfProperties"
                    v-model="numOfProperties"
                    mode="eager"
                    name="dropdown-number-of-properties"
                    :options="propertyList.map(i => i.id.toString())"
                    placeholder="How many rental properties do you own, fully and/or partially?"
                    rules="required"
                    required
                    error="This field is required"
                  />
                </div>
              </div>
              <div class="columns">
                <div class="column">
                  <vee-dropdown
                    id="propertyManager"
                    v-model="propertyManager"
                    mode="eager"
                    name="dropdown-property-manager"
                    :options="radioOptions.YesNo"
                    placeholder="Do you have a property manager?"
                    rules="required"
                    required
                    error="This field is required"
                  />
                </div>
              </div>
              <div
                v-for="property in propertyList"
                :key="property.id"
                :class="`entry-information-${property.id}`"
              >
                <div
                  v-if="
                    propertyList.length > 0 && numOfProperties >= property.id
                  "
                >
                  <hr style="background-color: black;" />
                  <div class="entry-container" style="margin-left: 45%;">
                    <label
                      ><b>Property {{ property.id }}</b></label
                    >
                  </div>
                  <h3>Address Search</h3>
                  <div class="columns">
                    <div class="column">
                      <textbox
                        :id="`ais-address-${property.id}`"
                        v-model="property.rawAddress"
                        :name="`txt-tenant-address-search-${property.id}`"
                        placeholder="Search for a City of Philadelphia Street Address..."
                        list.native="property.addressCandidateList"
                        @input="getAddressCandidatesFromAis(property)"
                      />
                    </div>
                  </div>
                  <div class="columns">
                    <div class="column">
                      <div
                        v-show="property.ShowCandidateTable"
                        class="table-container"
                      >
                        <div v-if="property.AddressCandidatesLoaded">
                          <h4 v-if="property.addressCandidates.length > 1">
                            We've found multiple addresses like that, please
                            select yours from the list below, or add more
                            information to the search above to narrow your
                            results
                          </h4>
                          <h4 v-else>
                            We've found a property like the one you entered
                            above, please select it from below to auto populate
                            the tenant address fields. Please fill in the
                            apartment / unit number below for the tenant if
                            applicable.
                          </h4>
                          <table>
                            <thead>
                              <tr>
                                <th>Address</th>
                                <th>ZIP Code</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                v-for="item in property.addressCandidates"
                                :key="item.id"
                              >
                                <td>
                                  <input
                                    :id="`candidate-${property.id}-${item.id}`"
                                    :value="item.selected"
                                    type="radio"
                                    name="selectedCandidate"
                                    @click="
                                      setSelectedAddressCandidate(
                                        property,
                                        item
                                      )
                                    "
                                  />
                                  <label
                                    class="address-label"
                                    :for="
                                      `candidate-${property.id}--${item.id}`
                                    "
                                    >{{ item.StreetAddress }}</label
                                  >
                                </td>
                                <td>{{ item.ZipCode }}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <div v-else>
                          <span>Loading...</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="columns">
                    <div class="column">
                      <vee-textbox
                        :id="`street1-${property.id}`"
                        v-model="property.street1"
                        mode="eager"
                        :name="`txt-street1-${property.id}`"
                        required
                        rules="required"
                        placeholder="Street Address 1"
                      />
                    </div>
                  </div>
                  <div class="columns">
                    <div class="column">
                      <vee-textbox
                        :id="`street2-${property.id}`"
                        v-model="property.street2"
                        mode="eager"
                        :name="`txt-street2-${property.id}`"
                        placeholder="Street Address 2"
                      />
                    </div>
                  </div>
                  <div class="columns">
                    <div class="column">
                      <vee-textbox
                        :id="`city-${property.id}`"
                        v-model="property.city"
                        mode="eager"
                        :name="`txt-city-${property.id}`"
                        required
                        rules="required"
                        placeholder="City"
                      />
                    </div>
                  </div>
                  <div class="columns">
                    <div class="column">
                      <vee-textbox
                        :id="`zip-${property.id}`"
                        v-model="property.zip"
                        mode="eager"
                        :name="`txt-zip-${property.id}`"
                        required
                        rules="required"
                        placeholder="Zip"
                      />
                    </div>
                    <div class="column">
                      <vee-textbox
                        :id="`state-${property.id}`"
                        v-model="property.state"
                        mode="eager"
                        :name="`txt-state-${property.id}`"
                        required
                        rules="required"
                        placeholder="State"
                      />
                    </div>
                  </div>
                  <div class="columns">
                    <div class="column">
                      <vee-dropdown
                        :id="`number-of-units-${property.id}`"
                        v-model="property.numOfUnits"
                        mode="eager"
                        :name="`dropdown-number-of-units-${property.id}`"
                        :options="Number_Of_Units"
                        placeholder="Number Of Units"
                        required
                        rules="required"
                      />
                    </div>
                    <div class="column">
                      <vee-dropdown
                        :id="`ownership-${property.id}`"
                        v-model="property.ownership"
                        mode="eager"
                        :name="`dropdown-ownership-${property.id}`"
                        :options="Type_Of_Ownership"
                        placeholder="Type Of Ownership"
                        required
                        rules="required"
                      />
                    </div>
                    <div class="column">
                      <vee-textbox
                        :id="`name-on-title-${property.id}`"
                        v-model="property.nameOnTitle"
                        mode="eager"
                        :name="`txt-name-on-title-${property.id}`"
                        placeholder="Name(s) on Title"
                        required
                        rules="required"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div class="column is-2 is-offset-10">
                  <button
                    type="button"
                    class="button is-primary"
                    @click="toAffidavitPage"
                  >
                    Enter
                  </button>
                </div>
              </div>
            </input-form>
          </ValidationObserver>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import options from "@/utilities/options.js";
import Property from "@/utilities/classes/Property.js";
import axios from "axios";
import { delay } from "@/utilities/functions";

export default {
  name: "Demographics",
  mixins: [options],
  props: {
    fName: { type: String, default: null },
    lName: { type: String, default: null },

    street1: { type: String, default: null },
    street2: { type: String, default: null },
    city: { type: String, default: null },
    state: { type: String, default: null },
    zipCode: { type: String, default: null },

    email: { type: String, default: null },
    phoneNum: { type: String, default: null },
    phoneExt: { type: String, default: null },
    cityEmployee: { type: String, default: null },
    comments: { type: String, default: null },
    RIFKnowledge: { type: String, default: null },
    RIFKnowledgeOther: { type: String, default: null },
    dateOfBirth: { type: String, default: null },
    preferredContact: { type: String, default: null },
    race: { type: String, default: null },
    ethnicity: { type: String, default: null },
    gender: { type: String, default: null },
    minority: { type: String, default: null }
  },
  data() {
    return {
      numOfProperties: null,
      propertyManager: null,
      propertyList: []
    };
  },
  created() {
    var vData = this;
    for (let i = 0; i < 15; i++) {
      const propertyObj = new Property(i + 1);
      vData.propertyList.push(propertyObj);
    }
  },
  methods: {
    async toAffidavitPage() {
      const isInputValid = await this.$refs.form.$parent.validate();
      if (isInputValid) {
        this.$router.push({
          name: "Affidavit",
          params: {
            fName: this.fName,
            lName: this.lName,

            street1: this.street1,
            street2: this.street2,
            city: this.city,
            state: this.state,
            zipCode: this.zipCode,

            email: this.email,
            phoneNum: this.phoneNum,
            phoneExt: this.phoneExt,
            cityEmployee: this.cityEmployee,
            RIFKnowledge: this.RIFKnowledge,
            RIFKnowledgeOther: this.RIFKnowledgeOther,
            dateOfBirth: this.dateOfBirth,
            preferredContact: this.preferredContact,
            race: this.race,
            ethnicity: this.ethnicity,
            gender: this.gender,
            minority: this.minority,
            numOfProperties: this.numOfProperties,
            propertyManager: this.propertyManager,
            propertyList: this.propertyList
          }
        });
      }
    },
    getAddressCandidatesFromAis(property) {
      property.AddressCandidatesLoaded = false;
      property.ShowCandidateTable = true;

      const aisUrl = `https://api.phila.gov/ais/v1/search/${property.rawAddress}?gatekeeperKey=2d130e3603f8931d82d39b3913bffe18`;
      delay(() => {
        axios.get(aisUrl).then(result => {
          if (result && result.data) {
            property.addressCandidates = result.data.features.map((i, idx) => ({
              id: idx,
              StreetAddress: i.properties.street_address,
              ZipCode: i.properties.zip_code,
              selected: false
            }));
            property.AddressCandidatesLoaded = true;
          } else {
            property.ShowCandidateTable = false;
            property.addressCandidates = [];
            property.addressCandidates = {};
          }
        });
      }, 1000);
    },
    setSelectedAddressCandidate(property, item) {
      property.street1 = item.StreetAddress;
      property.zip = item.ZipCode;
    }
  }
};
</script>
