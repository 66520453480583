export default class FileEntry {
  constructor(
    _id,
    _file,
    _contractorName,
    _paymentDate,
    _amountPaid,
    _description
  ) {
    this.id = _id;
    this.file = _file;
    this.contractorName = _contractorName;
    this.paymentDate = _paymentDate;
    this.amountPaid = _amountPaid;
    this.description = _description;
  }
}
