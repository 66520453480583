import DataObjectField from "@/utilities/classes/data-object-field";

const buildLandlordSubmissionDataObject = vData => {
  const fields = [
    new DataObjectField(vData.email, 6),
    new DataObjectField(vData.fName, 317),
    new DataObjectField(vData.lName, 318),
    new DataObjectField(vData.street1, 46),
    new DataObjectField(vData.street2, 47),
    new DataObjectField(vData.city, 48),
    new DataObjectField(vData.state, 49),
    new DataObjectField(vData.zipCode, 50),
    new DataObjectField("United States", 51),
    new DataObjectField(vData.email, 43),
    new DataObjectField(
      vData.phoneExt != ""
        ? vData.phoneNum + "x" + vData.phoneExt
        : vData.phoneNum,
      44
    ),
    new DataObjectField(vData.cityEmployee, 74),
    new DataObjectField(vData.RIFKnowledge, 320),
    new DataObjectField(vData.RIFKnowledgeOther, 321),
    new DataObjectField(vData.dateOfBirth, 41),
    new DataObjectField(vData.preferredContact, 42),
    new DataObjectField(vData.race, 52),
    new DataObjectField(vData.ethnicity, 53),
    new DataObjectField(vData.gender, 54),
    new DataObjectField(vData.minority, 55),
    new DataObjectField(vData.numOfProperties, 271),
    new DataObjectField(vData.propertyManager, 56),
    new DataObjectField(vData.signature, 290),
    new DataObjectField(vData.date, 291)
  ];

  var propertyFieldId = 119;
  var numOfUnitsFieldId = 125;
  var ownershipFieldId = 126;
  var nameOnTitleFieldId = 127;
  for (var n = 0; n < vData.propertyList.length; n++) {
    var curr = vData.propertyList[n];
    fields.push(new DataObjectField(curr.street1, propertyFieldId++));
    fields.push(new DataObjectField(curr.street2, propertyFieldId++));
    fields.push(new DataObjectField(curr.city, propertyFieldId++));
    fields.push(new DataObjectField(curr.state, propertyFieldId++));
    fields.push(new DataObjectField(curr.zip, propertyFieldId++));
    fields.push(new DataObjectField("United States", propertyFieldId++));
    fields.push(new DataObjectField(curr.numOfUnits, numOfUnitsFieldId));
    fields.push(new DataObjectField(curr.ownership, ownershipFieldId));
    fields.push(new DataObjectField(curr.nameOnTitle, nameOnTitleFieldId));
    propertyFieldId = propertyFieldId + 4;
    numOfUnitsFieldId = numOfUnitsFieldId + 10;
    ownershipFieldId = ownershipFieldId + 10;
    nameOnTitleFieldId = nameOnTitleFieldId + 10;
  }

  const dataObject = {
    qdbapi: {
      udata: "_RIF_LANDLORD_PROFILE",
      apptoken: "d8phrhgbq4mmfwc3rkhnddciskd3",
      field: fields
    }
  };
  return dataObject;
};

export default buildLandlordSubmissionDataObject;
