import DataObjectField from "@/utilities/classes/data-object-field";

const buildFileUploadSubmissionDataObject = (
  profileId,
  fileName,
  base64File,
  fileType,
  unitNumber
) => {
  const dataObject = {
    qdbapi: {
      udata: "RIF_FILE_UPLOAD",
      apptoken: "d8phrhgbq4mmfwc3rkhnddciskd3",
      field: [
        new DataObjectField(fileType, 6),
        new DataObjectField(unitNumber, 27),
        {
          _fid: "19",
          __text: profileId
        },
        {
          _fid: "7",
          _filename: fileName,
          __text: base64File
        }
      ]
    }
  };

  return dataObject;
};

export default buildFileUploadSubmissionDataObject;
