<template>
  <ValidationObserver v-slot="{ errors }" tag="div">
    <input-form
      ref="form"
      :errors="errors"
      name="StatusFrm"
      @submit.prevent="handleSubmit"
    >
      <div class="content">
        <br />
        <h3>Rental Improvement Fund - Check Application Status</h3>
        <div class="container">
          <div class="column">
            <callout type="info"
              >Use the form below to view the status of your loan application(s)
              and take any required actions to move your loan(s) forward. You’ll
              need to enter the same email address you used to create your RIF
              Landlord Profile, and the verification code that was sent to you
              when you created your profile.
            </callout>
          </div>
        </div>
        <div class="container">
          <div class="column">
            <vee-textbox
              v-model="emailID"
              error="This field is required for the status check"
              name="input-email"
              required
              rules="required_status"
              placeholder="Enter your Email"
              type="text"
            />
          </div>
          <div class="column">
            <vee-textbox
              v-model="verificationCode"
              name="input-verification-code"
              required
              error="This field is required for the status check"
              rules="required_status"
              placeholder="Enter your verification code"
              type="text"
            />
          </div>
          <div v-html="statusResult"></div>
          <div class="column is-2 is-offset-10">
            <button
              type="button"
              class="button is-primary"
              @click="handleSubmit"
            >
              Enter
            </button>
          </div>
        </div>
      </div>
    </input-form>
  </ValidationObserver>
</template>
<script>
import options from "@/utilities/options.js";
import buildStatusCheckObject from "@/utilities/get-status-check-object.js";
import { getPropertyValueOrDefault } from "@/utilities/functions.js";
import axios from "axios";
export default {
  name: "Status",
  mixins: [options],
  data() {
    return {
      emailID: null,
      verificationCode: null,
      statusResult: null
    };
  },
  methods: {
    async handleSubmit() {
      // Returns the information about the selected session
      var vData = this;
      const isInputValid = await vData.$refs.form.$parent.validate();
      if (isInputValid) {
        const dataObject = buildStatusCheckObject(
          vData.emailID,
          vData.verificationCode,
          "RIF_GET_STATUS_INFO"
        );
        const requestObj = {
          table: "bs2bx26ex",
          data: dataObject
        };
        axios
          .post(
            "https://djql8jp3sb.execute-api.us-east-1.amazonaws.com/Rental-Improvement-Fund-Quickbase-Request-Proxy-Reads",
            requestObj
          )
          .then(response => {
            const responseObject = getPropertyValueOrDefault(
              ["data", "record", "f"],
              response
            );
            vData.statusResult = getPropertyValueOrDefault(
              ["_text"],
              responseObject.find(i => i._attributes.id === "293")
            );
          });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.columns,
.column,
.content div {
  margin-bottom: 0;
}
.address-label {
  padding-left: 5px;
}
</style>
