const options = {
  data() {
    return {
      radioOptions: {
        YesNo: ["Yes", "No"]
      },
      Number_Of_Units: [
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15"
      ],
      Type_Of_Ownership: [
        "My name is on the deed",
        "I am the full owner of the LLC for this property",
        "I am a partial owner of the LLC for this property"
      ],
      Change_Status: ["Add", "Remove", "Change"],
      Repair_Replace: ["Repair", "Replace"],
      Wiring_Workscope: [
        "Small (one room)",
        "Medium (one floor)",
        "Whole house"
      ],
      inqueryIncluded: [
        "Landlord Gateway",
        "PHDC Housing Fair",
        "L&I referral",
        "Neighborhood organization referral",
        "PHA referral",
        "Word of mouth (friend/neighbor/family member)",
        "Online search engine",
        "Other"
      ],
      raceOptions: [
        "American Indian/Alaska Native",
        "Asian",
        "Black",
        "Pacific Islander",
        "Two or more races",
        "White",
        "Decline to answer",
        "Illyrian"
      ],
      ethnicityOptions: [
        "Not Hispanic or Latino",
        "Hispanic or Latino",
        "Decline to Answer",
        "Albanian"
      ],
      genderOptions: ["Female", "Male", "Non-binary", "Decline to answer"],
      RequestTypes: ["Questions about eligibility", "Other questions"],
      InspectionRequestTypes: [
        "Interim inspection (60% of work complete)",
        "Final inspection (100% of work complete)"
      ],
      Roof_Gutters_Chimney_Location: ["Main", "Porch", "Rear"],
      Fence_Gate_Location: ["Front", "Back", "Side"],
      Masonry_Siding_Type: [
        "Brick/stone pointing",
        "Stucco",
        "Siding",
        "Retaining wall"
      ],
      Flooring_Type: ["Carpet", "Vinyl", "Tile", "Wood"],
      Fire_Type: ["Sprinklers", "Smoke/CO2 Detectors", "Central Fire System"],
      Stairs_railing_Type: ["Replace", "Repair"],
      Pest_Type: ["Roaches", "Rodents", "Bedbugs", "Other"],
      Kitchen_Type: ["Sink", "Refrigerator", "Stove", "Cabinets", "Countertop"],
      Electrical_WorkScope: [
        "Service Cable",
        "Service Panel",
        "Junction Box",
        "Meter Socket",
        "Lighting"
      ],
      HVAC_WorkScope: [
        "heat pump",
        "gas furnace",
        "radiators",
        "baseboard",
        "condenser"
      ],
      preferredOptions: ["Email", "Text", "Mail", "Phone"],
      Bathroom_Fixtures: ["Shower/tub", "Vanity", "Faucet", "Toilet"],
      WorkScopeSelection: {
        ExteriorRepairs: [
          { value: "Roof/Gutters/Chimney", text: "Roof/Gutters/Chimney" },
          { value: "Masonry/Siding", text: "Masonry/Siding" },
          { value: "Fence/Gate", text: "Fence/Gate" },
          { value: "Windows", text: "Windows" },
          { value: "Exterior Doors", text: "Exterior Doors" },
          {
            value: "Sidewalk & Exterior Stairs",
            text: "Sidewalk & Exterior Stairs"
          },
          { value: "Tree Removal", text: "Tree Removal" },
          { value: "Porch/Deck", text: "Porch/Deck" },
          { value: "Exterior Concrete", text: "Exterior Concrete" }
        ],
        InteriorRepairs: [
          { value: "Flooring", text: "Flooring" },
          { value: "Drywall", text: "Drywall" },
          { value: "Stairs/railing", text: "Stairs/railing" },
          { value: "Painting", text: "Painting" },
          { value: "Bathroom", text: "Bathroom" },
          { value: "Interior Doors", text: "Interior Doors" },
          { value: "Kitchen", text: "Kitchen" },
          { value: "Interior Concrete", text: "Interior Concrete" },
          { value: "Interior Masonry", text: "Interior Masonry" }
        ],
        BuildingSystems: [
          { value: "Water Heater", text: "Water Heater" },
          { value: "Laundry", text: "Laundry" },
          { value: "Main Drain", text: "Main Drain" },
          { value: "Electrical", text: "Electrical" },
          { value: "Solar", text: "Solar" },
          { value: "HVAC", text: "HVAC" },
          { value: "Structural Repairs", text: "Structural Repairs" },
          { value: "Fire Protection", text: "Fire Protection" },
          { value: "Plumbing", text: "Plumbing" }
        ],
        Health: [
          { value: "Lead Remediation", text: "Lead Remediation" },
          { value: "Mold Remediation", text: "Mold Remediation" },
          { value: "Pest Control", text: "Pest Control" },
          { value: "Adaptive Modifications", text: "Adaptive Modifications" },
          { value: "Asbestos Remediation", text: "Asbestos Remediation" }
        ]
      },
      SessionTypes: ["Virtual", "In-Person"],
      ContactMethods: ["Phone", "Email", "In Person Appointment"],
      UnitSizes: [
        "0 Bedrooms (studio)",
        "1 Bedroom",
        "2 Bedrooms",
        "3 Bedrooms",
        "4 Bedrooms",
        "5 Bedrooms",
        "6 Bedrooms",
        "7 Bedrooms",
        "8 Bedrooms"
      ],
      FileTypes: [
        "Lease",
        "Proof of Funds",
        "Contractor Estimate",
        "Tax Clearance Certificate",
        "Deed from Philadox",
        "Property Insurance",
        "Lead Certificate",
        "Notice to Tenant",
        "Tenant Income Attestation",
        "Lease Amendment",
        "Insurance Certification",
        "Signed Note",
        "Signed Mortgage",
        "Signed Application",
        "Bank Document",
        "Valid ID",
        "RIF loan expenditure summary",
        "Payment invoices and receipts",
        "Photo",
        "Other"
      ],
      States: [
        "Alabama",
        "Alaska",
        "American Samoa",
        "Arizona",
        "Arkansas",
        "California",
        "Colorado",
        "Connecticut",
        "Delaware",
        "District of Columbia",
        "Federated States of Micronesia",
        "Florida",
        "Georgia",
        "Guam",
        "Hawaii",
        "Idaho",
        "Illinois",
        "Indiana",
        "Iowa",
        "Kansas",
        "Kentucky",
        "Louisiana",
        "Maine",
        "Marshall Islands",
        "Maryland",
        "Massachusetts",
        "Michigan",
        "Minnesota",
        "Mississippi",
        "Missouri",
        "Montana",
        "Nebraska",
        "Nevada",
        "New Hampshire",
        "New Jersey",
        "New Mexico",
        "New York",
        "North Carolina",
        "North Dakota",
        "Northern Mariana Islands",
        "Ohio",
        "Oklahoma",
        "Oregon",
        "Palau",
        "Pennsylvania",
        "Puerto Rico",
        "Rhode Island",
        "South Carolina",
        "South Dakota",
        "Tennessee",
        "Texas",
        "Utah",
        "Vermont",
        "Virgin Island",
        "Virginia",
        "Washington",
        "West Virginia",
        "Wisconsin",
        "Wyoming"
      ],
      Countries: [
        "United States",
        "Afghanistan",
        "Aland Islands",
        "Albania",
        "Algeria",
        "American Samoa",
        "Andorra",
        "Angola",
        "Anguilla",
        "Antarctica",
        "Antigua and Barbuda",
        "Argentina",
        "Armenia",
        "Aruba",
        "Australia",
        "Austria",
        "Azerbaijan",
        "Bahamas",
        "Bahrain",
        "Bangladesh",
        "Barbados",
        "Belarus",
        "Belgium",
        "Belize",
        "Benin",
        "Bermuda",
        "Bhutan",
        "Bolivia, Plurinational State of",
        "Bonaire, Sint Eustatius and Saba",
        "Bosnia and Herzegovina",
        "Botswana",
        "Bouvet Island",
        "Brazil",
        "British Indian Ocean Territory",
        "Brunei Darussalam",
        "Bulgaria",
        "Burkina Faso",
        "Burundi",
        "Cambodia",
        "Cameroon",
        "Canada",
        "Cape Verde",
        "Cayman Islands",
        "Central African Republic",
        "Chad",
        "Chile",
        "China",
        "Christmas Island",
        "Cocos (Keeling) Islands",
        "Colombia",
        "Comoros",
        "Congo",
        "Congo, The Democratic Republic of the",
        "Cook Islands",
        "Costa Rica",
        "Cote d'Ivoire",
        "Croatia",
        "Cuba",
        "Curacao",
        "Cyprus",
        "Czech Republic",
        "Denmark",
        "Djibouti",
        "Dominica",
        "Dominican Republic",
        "Ecuador",
        "Egypt",
        "El Salvador",
        "Equatorial Guinea",
        "Eritrea",
        "Estonia",
        "Ethiopia",
        "Falkland Islands (Malvinas)",
        "Faroe Islands",
        "Fiji",
        "Finland",
        "France",
        "French Guiana",
        "French Polynesia",
        "French Southern Territories",
        "Gabon",
        "Gambia",
        "Georgia",
        "Germany",
        "Ghana",
        "Gibraltar",
        "Greece",
        "Greenland",
        "Grenada",
        "Guadeloupe",
        "Guam",
        "Guatemala",
        "Guernsey",
        "Guinea",
        "Guinea-Bissau",
        "Guyana",
        "Haiti",
        "Heard Island and McDonald Islands",
        "Holy See (Vatican City State)",
        "Honduras",
        "Hong Kong",
        "Hungary",
        "Iceland",
        "India",
        "Indonesia",
        "Iran, Islamic Republic of",
        "Iraq",
        "Ireland",
        "Isle of Man",
        "Israel",
        "Italy",
        "Jamaica",
        "Japan",
        "Jersey",
        "Jordan",
        "Kazakhstan",
        "Kenya",
        "Kiribati",
        "Korea, Democratic People's Republic of",
        "Korea, Republic of",
        "Kosovo",
        "Kuwait",
        "Kyrgyzstan",
        "Lao People's Democratic Republic",
        "Latvia",
        "Lebanon",
        "Lesotho",
        "Liberia",
        "Libya",
        "Liechtenstein",
        "Lithuania",
        "Luxembourg",
        "Macao",
        "Macedonia, The Former Yugoslav Republic of",
        "Madagascar",
        "Malawi",
        "Malaysia",
        "Maldives",
        "Mali",
        "Malta",
        "Marshall Islands",
        "Martinique",
        "Mauritania",
        "Mauritius",
        "Mayotte",
        "Mexico",
        "Micronesia, Federated States of",
        "Moldova, Republic of",
        "Monaco",
        "Mongolia",
        "Montenegro",
        "Montserrat",
        "Morocco",
        "Mozambique",
        "Myanmar",
        "Namibia",
        "Nauru",
        "Nepal",
        "Netherlands",
        "New Caledonia",
        "New Zealand",
        "Nicaragua",
        "Niger",
        "Nigeria",
        "Niue",
        "Norfolk Island",
        "Northern Cyprus",
        "Northern Mariana Islands",
        "Norway",
        "Oman",
        "Pakistan",
        "Palau",
        "Palestine, State of",
        "Panama",
        "Papua New Guinea",
        "Paraguay",
        "Peru",
        "Philippines",
        "Pitcairn Islands",
        "Poland",
        "Portugal",
        "Puerto Rico",
        "Qatar",
        "Reunion",
        "Romania",
        "Russian Federation",
        "Rwanda",
        "Western Sahara",
        "Saint Barthelemy",
        "Saint Helena, Ascension and Tristan da Cunha",
        "Saint Kitts and Nevis",
        "Saint Lucia",
        "Saint Martin (French Part)",
        "Saint Pierre and Miquelon",
        "Saint Vincent and the Grenadines",
        "Samoa",
        "San Marino",
        "Sao Tome and Principe",
        "Saudi Arabia",
        "Senegal",
        "Serbia",
        "Seychelles",
        "Sierra Leone",
        "Singapore",
        "Sint Maarten (Dutch Part)",
        "Slovakia",
        "Slovenia",
        "Solomon Islands",
        "Somalia",
        "Somaliland",
        "South Africa",
        "South Georgia and the South Sandwich Islands",
        "South Ossetia",
        "South Sudan",
        "Spain",
        "Sri Lanka",
        "Sudan",
        "Suriname",
        "Svalbard and Jan Mayen",
        "Swaziland",
        "Sweden",
        "Switzerland",
        "Syrian Arab Republic",
        "Taiwan, Province of China",
        "Tajikistan",
        "Tanzania, United Republic of",
        "Thailand",
        "Timor-Leste",
        "Togo",
        "Tokelau",
        "Tonga",
        "Transnistria",
        "Trinidad and Tobago",
        "Tunisia",
        "Turkey",
        "Turkmenistan",
        "Turks and Caicos Islands",
        "Tuvalu",
        "Uganda",
        "Ukraine",
        "United Arab Emirates",
        "United Kingdom",
        "United States Minor Outlying Islands",
        "Uruguay",
        "Uzbekistan",
        "Vanuatu",
        "Venezuela, Bolivarian Republic of",
        "Vietnam",
        "Virgin Islands, British",
        "Virgin Islands, US",
        "Wallis and Futuna",
        "Yemen",
        "Zambia",
        "Zimbabwe"
      ],
      SubmitStatus: {
        value: null,
        Options: {
          PENDING: "PENDING",
          OK: "OK",
          NOT_FOUND: "NOT_FOUND",
          ERROR: "ERROR"
        }
      },
      NumberOfFileEntries: 5
    };
  }
};

export default options;
