<template>
  <div class="Affidavit">
    <div class="container">
      <div class="columns is-centered">
        <div class="column">
          <div class="content">
            <div class="column">
              <img
                style="margin-left: 30%;"
                src="../assets/PHDC-rental-repair-logo-x-450.jpg"
              />
              <br />
              <label
                ><em
                  ><b
                    >Thank you for for your interest in the Rental Improvement
                    Fund. The first step is to create a Landlord Profile, below.
                    Afterwards, you will receive a confirmation email that
                    includes a link to submit a Rental Improvement Fund loan
                    application. If have any technical issues with the
                    application process, please email
                    <a href="mailto:RentalImprove@phila.gov"
                      >RentalImprove@phila.gov</a
                    ></b
                  ></em
                ></label
              >
            </div>
            <ValidationObserver v-slot="{ errors }" tag="div">
              <input-form
                ref="form"
                :errors="errors"
                name="projectDocumentsFrm"
                @submit.prevent="handleSubmit"
              >
                <br />

                <h2><b>Affidavit</b></h2>
                <br />

                <h4><b>I/We hereby certify, attest, and affirm that:</b></h4>

                <p>
                  I/We have an ownership stake in
                  <strong
                    >NO MORE THAN FIVE (5) PROPERTIES AND NO MORE THAN FIFTEEN
                    (15) RENTAL UNITS IN THE AGGREGATE.</strong
                  >
                </p>

                <p>The information listed hereinabove is true and correct</p>

                <p>
                  I authorize, and give my consent to, PHDC, and its officers,
                  agents, employees, representatives, successors, and assigns,
                  to use, share, provide, distribute, disseminate, disclose,
                  make available, receive, analyze, and discuss any and all
                  information that I included in the Application, whether such
                  information is about me or properties I own, including, but
                  not limited to, any and all personally identifiable
                  information (e.g., name(s), home address, date(s) of birth,
                  etc.) and contact information (e.g., home telephone number,
                  cell phone number, email address, etc.) with the Philadelphia
                  Redevelopment Authority, the City of Philadelphia (the "City")
                  and any department or agency of the City, and any and all
                  entities that may manage, administer, fund, and/or service the
                  Program, and their respective officers, agents, employees,
                  representatives, successors, and assigns of any of the
                  foregoing.
                </p>

                <p>
                  I understand that it is a federal offense (18 U.S. Code §1001)
                  and state offense (18 Pa.C.S.A. §4904) to knowingly make a
                  false statement in this affidavit. I have read the information
                  carefully to be sure that the information contained herein is
                  true and complete before signing. I understand that the
                  information provided in this affidavit is subject to
                  verification. I understand that my completion of this
                  affidavit or any other document related thereto does not
                  represent any commitment or obligation to be awarded or
                  provided a grant under the Philadelphia Housing Development
                  Corporation (PHDC) Rental Improvement Fund.
                </p>

                <p>
                  As of the date hereof, I have not filed in any court having
                  jurisdiction a voluntary petition in bankruptcy or any
                  petition or other pleading seeking a readjustment, liquidation
                  or similar relief for itself, and I have not been served
                  notice of any pleading being filed against me seeking an
                  adjudication of bankruptcy, reorganization, composition,
                  readjustment, liquidation or similar relief under any law or
                  regulation.
                </p>

                <p>
                  I agree that this affidavit may be electronically signed, that
                  any electronic signature appearing on this affidavit is the
                  same as a handwritten signature for the purposes of validity,
                  enforceability, and admissibility, and that a signature
                  provided through electronic means may not be denied legal
                  effect solely because it is in electronic form. I understand
                  that by typing my name below, it will serve as my electronic
                  signature.
                </p>

                <p>
                  I affix my name hereto and understand that any false or
                  misleading statements or omissions made herein or in any other
                  documentation or information submitted by me or on my behalf
                  in connection with a Rental Improvement Fund loan may result
                  in the denial of my Rental Improvement Fund loan application
                  and my disqualification from this or any future PHDC
                  assistance programs, and further may result in criminal and
                  civil actions for fines, penalties, damages, or imprisonment.
                </p>
                <br />

                <div class="columns">
                  <div style="margin-left: 0.5%;" class="column">
                    <input
                      style="width: 30%; height: 70%; margin-left: -14%;"
                      v-model="agreeCheckbox"
                      true-value="1"
                      false-value="0"
                      type="checkbox"
                    />
                    <label style="margin-left: -12%;"
                      >I agree to terms of the program</label
                    >
                  </div>
                </div>
                <div class="columns">
                  <div class="column">
                    <vee-textbox
                      id="signature"
                      v-model="signature"
                      label="Signature of Declarant"
                      placeholder="Type your full name as signature"
                      required
                      rules="required"
                    />
                  </div>

                  <div class="column">
                    <vee-textbox
                      id="date"
                      v-model="date"
                      type="date"
                      label="Date"
                      required
                      rules="required"
                    />
                  </div>
                </div>

                <div class="columns">
                  <div class="column is-2 is-offset-10">
                    <button
                      type="button"
                      class="button is-primary"
                      @click="handleSubmit"
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </input-form>
            </ValidationObserver>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import options from "@/utilities/options.js";
import axios from "axios";
import buildLandlordSubmissionDataObject from "../utilities/landlord-profile-submission-object.js";
import { dataObjectToXml } from "@/utilities/functions.js";

export default {
  name: "Affidavit",
  mixins: [options],
  props: {
    fName: { type: String, default: null },
    lName: { type: String, default: null },

    street1: { type: String, default: null },
    street2: { type: String, default: null },
    city: { type: String, default: null },
    state: { type: String, default: null },
    zipCode: { type: String, default: null },

    email: { type: String, default: null },
    phoneNum: { type: String, default: null },
    phoneExt: { type: String, default: null },
    cityEmployee: { type: String, default: null },
    RIFKnowledge: { type: String, default: null },
    RIFKnowledgeOther: { type: String, default: null },
    dateOfBirth: { type: String, default: null },
    preferredContact: { type: String, default: null },
    race: { type: String, default: null },
    ethnicity: { type: String, default: null },
    gender: { type: String, default: null },
    minority: { type: String, default: null },
    numOfProperties: { type: String, default: null },
    propertyManager: { type: String, default: null },
    propertyList: { type: Array, default: null }
  },
  data() {
    return {
      signature: null,
      date: null,
      agreeCheckbox: null,
      showValidation: false
    };
  },
  methods: {
    async handleSubmit() {
      var vData = this;
      vData.SubmitStatus.Value = vData.SubmitStatus.Options.PENDING;
      const isInputValid = await vData.$refs.form.$parent.validate();
      if (isInputValid) {
        const dataObject = buildLandlordSubmissionDataObject(vData);
        const xmlDataObject = dataObjectToXml(dataObject);
        const requestObject = {
          table: "bs2bx26ex",
          action: "API_AddRecord",
          data: xmlDataObject
        };

        axios
          .post(
            "https://djql8jp3sb.execute-api.us-east-1.amazonaws.com/Rental-Improvement-Fund-QuickBase-Request-Proxy",
            requestObject
          )
          .then(response => {
            console.log(response);
            vData.SubmitStatus.Value = vData.SubmitStatus.Options.OK;
            vData.$router.push({
              name: "SuccessInspection"
            });
          })
          .catch(() => {
            vData.SubmitStatus.Value = vData.SubmitStatus.Options.ERROR;
          });
      } else {
        vData.SubmitStatus.Value = vData.SubmitStatus.Options.ERROR;
      }
    }
  },
  created() {
    console.log("HERE");
    console.log(this.propertyList);
  }
};
</script>
