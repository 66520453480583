<template>
  <div class="container">
    <div class="columns is-centered">
      <div class="column">
        <ValidationObserver v-slot="{ errors }" tag="div">
          <input-form
            ref="form"
            :errors="errors"
            name="InvoiceUploadsForm"
            @submit.prevent="handleFileUpload"
          >
            <div class="content">
              <br />
              <h2>Rental Improvement Fund File Uploads</h2>
              <h3>Upload Required Documents</h3>
            </div>
            <callout type="info">
              <div>
                The file upload accepts images and PDFs, with a maximum file
                size of 4.5mb. You may select multiple documents at a time.
              </div>
            </callout>
            <callout type="danger">
              <strong>
                IMPORTANT -- Please follow these steps to upload documents
                successfully:
              </strong>
              <ol style="margin-left: 3%;">
                <li>
                  Select the files you wish to upload using the blue button
                  below.
                </li>
                <!--<li>The "Finish" button will appear in the bottom right corner of this page when documents have been selected for required fields.</li>-->
                <li>
                  Once all files have been Selected, Click the Finish button to
                  upload them.
                </li>
                <li>
                  Do not hit the back button. Wait for the green message that
                  all documents have uploaded before closing this page.
                </li>
              </ol>
            </callout>
            <div>
              <label> <b>Property Owner Name:</b> {{ propertyName }} </label>
            </div>
            <div>
              <label> <b>Property Address:</b> {{ propertyAddress }} </label>
            </div>
            <div class="columns">
              <div class="column">
                <vee-dropdown
                  v-model="FileEntry.NumberOfFileEntriesSelected"
                  label="How many files are you submitting?"
                  :options="FileEntry.fileEntryList.map(i => i.id.toString())"
                  placeholder="How many files are you submitting?"
                  mode="eager"
                  @change="refresh(FileEntry.NumberOfFileEntriesSelected)"
                />
              </div>
            </div>
            <div
              v-for="fileEntryObj in FileEntry.fileEntryList"
              :key="fileEntryObj.id"
              :class="`entry-information-${fileEntryObj.id}`"
            >
              <div
                v-if="
                  FileEntry.NumberOfFileEntriesSelected !== null &&
                    FileEntry.NumberOfFileEntriesSelected >= fileEntryObj.id
                "
              >
                <div class="field-container file-uploads">
                  <h3><b>File Uploads</b></h3>
                </div>
                <h5>Additional Files:</h5>
                <ul id="upload-list-landlord-documents">
                  <li
                    v-for="currentFile in fileEntryObj.file"
                    :key="currentFile.id"
                  >
                    <span>{{ currentFile.name }} - </span>
                    <span v-if="currentFile.error" class="is-love-park-red">
                      Something went wrong - {{ currentFile.errorMessage }}
                    </span>

                    <span
                      v-else-if="currentFile.success"
                      class="is-phanatic-green"
                    >
                      Upload Successful. Please select another document.
                    </span>
                    <span
                      v-else-if="currentFile.uploading"
                      class="is-flyers-orange"
                    >
                      Uploading Document...
                    </span>
                  </li>
                </ul>
                <file-upload
                  ref="upload"
                  v-model="fileEntryObj.file"
                  :input-id="`file-upload-landlord-file-${fileEntryObj.id}`"
                  extensions="gif,jpg,jpeg,png,webp,pdf"
                  accept="image/png,image/gif,image/jpeg,image/webp,application/pdf"
                  :size="1024 * 1024 * 4.5"
                  @input-filter="inputFilter"
                >
                  <button class="button is-primary" style="margin-bottom: 1%;">
                    <i class="fa fa-plus" />
                    Select Files to Upload
                  </button>
                </file-upload>
                <div class="columns">
                  <div class="column">
                    <vee-dropdown
                      v-model="fileEntryObj.typeOfFile"
                      label="Select file type"
                      :input-id="`file-type-landlord-file-${fileEntryObj.id}`"
                      :options="FileTypes"
                      rules="required"
                      required
                      placeholder="Select File Type"
                      mode="eager"
                    />
                  </div>
                </div>
                <div
                  v-if="fileEntryObj.typeOfFile == 'Tenant Income Attestation'"
                  class="columns"
                >
                  <div class="column">
                    <vee-textbox
                      v-model="fileEntryObj.unitNumber"
                      name="input-unit-number"
                      placeholder="Enter unit number"
                      rules="required"
                    />
                  </div>
                </div>
              </div>
            </div>
            <p>
              <b>
                Once all necessary files are selected, click FINISH to upload
                them.
              </b>
            </p>
            <div class="actions columns">
              <!--Required criteria for above div: v-if="(ProofOfDeliveryDocument.length != 0 && RentLedger.length != 0)"-->
              <div class="column is-2 is-offset-20" style="margin-top:2%">
                <button
                  class="button is-primary"
                  :disabled="
                    SubmitStatus.Value === 'PENDING' ||
                      SubmitStatus.Value === 'OK'
                  "
                  @click="
                    handleFileUpload(
                      FileEntry.fileEntryList,
                      FileEntry.NumberOfFileEntriesSelected
                    )
                  "
                >
                  FINISH
                </button>
              </div>
            </div>
          </input-form>
        </ValidationObserver>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

import FileUpload from "vue-upload-component";
import options from "@/utilities/options";

import buildFileUploadSubmissionDataObject from "@/utilities/file-upload-data-object";
import FileEntry from "@/utilities/classes/FileEntry";
import buildPropertyInfoObject from "@/utilities/get-property-info-object";
//import buildLandlordDataFetchObject from "@/utilities/data-objects/landlord-data-fetch-object";
import {
  dataObjectToXml,
  resizeImage,
  getBase64,
  getPropertyValueOrDefault
} from "@/utilities/functions";

export default {
  name: "Files",
  components: { FileUpload },
  mixins: [options],
  props: {
    profileId: { default: null, type: String }
  },
  data() {
    return {
      propertyName: null,
      propertyAddress: null,
      FileEntry: {
        fileEntryList: [],
        NumberOfFileEntriesSelected: null,
        file: [],
        typeOfFile: null,
        unitNumber: null
      }
    };
  },
  /*created() {
    var vData = this;
    const dataObject = buildFileUploadSubmissionDataObject(
      vData.applicantId,
      "EDP_FILE_UPLOAD_DATA_REQUEST"
    );
    const requestObj = {
      table: "bqseac2yd",
      data: dataObject
    };
    axios
      .post(
        "https://djql8jp3sb.execute-api.us-east-1.amazonaws.com/V1/Eviction-Diversion-Secure-Request-Proxy",
        requestObj
      )
      .then(response => {
        const responseObject = getPropertyValueOrDefault(
          ["data", "record", "f"],
          response
        );
        vData.TenantName = getPropertyValueOrDefault(
          ["_text"],
          responseObject.find(i => i._attributes.id === "65")
        );
        vData.TenantAddress = getPropertyValueOrDefault(
          ["_text"],
          responseObject.find(i => i._attributes.id === "23")
        );
      });
  },*/
  created() {
    var vData = this;
    for (let i = 0; i < vData.NumberOfFileEntries; i++) {
      const fileEntryObj = new FileEntry(i + 1);
      vData.FileEntry.fileEntryList.push(fileEntryObj);
    }
    const dataObject = buildPropertyInfoObject(
      vData.profileId,
      "RIF_GET_PROPERTY_INFO"
    );
    const requestObj = {
      table: "btbsqbhjn",
      data: dataObject
    };
    axios
      .post(
        "https://djql8jp3sb.execute-api.us-east-1.amazonaws.com/Rental-Improvement-Fund-Quickbase-Request-Proxy-Reads",
        requestObj
      )
      .then(response => {
        console.log(response);
        const responseObject = getPropertyValueOrDefault(
          ["data", "record", "f"],
          response
        );
        vData.propertyName = getPropertyValueOrDefault(
          ["_text"],
          responseObject.find(i => i._attributes.id === "301")
        );
        vData.propertyAddress = getPropertyValueOrDefault(
          ["_text"],
          responseObject.find(i => i._attributes.id === "6")
        );
      });
  },
  methods: {
    inputFilter(newFile, oldFile, prevent) {
      if (newFile && !oldFile) {
        // Before adding a file
        // Filter system files or hide files
        if (/(\/|^)(Thumbs\.db|desktop\.ini|\..+)$/.test(newFile.name)) {
          return prevent();
        }
        // Filter php html js file
        if (/\.(php5?|html?|jsx?|exe?)$/i.test(newFile.name)) {
          return prevent();
        }
      }
    },
    refresh(NumberOfFiles) {
      if (NumberOfFiles == 1) {
        window.history.replaceState(
          null,
          null,
          "/?file=#/fileupload/" + this.profileId
        );
      }
      if (NumberOfFiles == 2) {
        window.history.replaceState(
          null,
          null,
          "/?file=&file=#/fileupload/" + this.profileId
        );
      }
      if (NumberOfFiles == 3) {
        window.history.replaceState(
          null,
          null,
          "/?file=&file=&file=#/fileupload/" + this.profileId
        );
      }
      if (NumberOfFiles == 4) {
        window.history.replaceState(
          null,
          null,
          "/?file=&file=&file=&file=#/fileupload/" + this.profileId
        );
      }
      if (NumberOfFiles == 5) {
        window.history.replaceState(
          null,
          null,
          "/?file=&file=&file=&file=&file=#/fileupload/" + this.profileId
        );
      }
    },
    async handleFileUpload(uploadData, entryIdx) {
      var vData = this;
      vData.SubmitStatus.Value = vData.SubmitStatus.Options.PENDING;
      // var uploadData = vData.FileEntry.fileEntryList;
      // var entryIdx = vData.FileEntry.NumberOfFileEntriesSelected;
      const isInputValid = await vData.$refs.form.$parent.validate();
      if (isInputValid) {
        if (uploadData.length) {
          for (var i = 0; i < entryIdx; i++) {
            let FileIdx = uploadData[i].file.length - 1;
            var fileObj = uploadData[i].file[FileIdx];
            fileObj.uploading = true;
            fileObj.error = false;
            fileObj.errorMessage = "";
            let file = fileObj.file;
            if (fileObj.type.match(/image.*/)) {
              file = await resizeImage({ file: file, maxSize: 1920 });
            }
            if (file.size >= 1024 * 1024 * 4.5) {
              fileObj.error = true;
              fileObj.errorMessage =
                "File is too large, please reduce your file's size and try again.";
              vData.SubmitStatus.Value = vData.SubmitStatus.Options.ERROR;
            } else {
              const base64File = await getBase64(fileObj.file);
              const dataObject = buildFileUploadSubmissionDataObject(
                vData.profileId,
                fileObj.name,
                base64File,
                uploadData[i].typeOfFile,
                uploadData[i].unitNumber
              );
              const xmlDataObject = dataObjectToXml(dataObject);
              const requestObj = {
                table: "bs3rxqfik",
                action: "API_AddRecord",
                data: xmlDataObject
              };
              axios
                .post(
                  "https://djql8jp3sb.execute-api.us-east-1.amazonaws.com/Rental-Improvement-Fund-QuickBase-Request-Proxy",
                  requestObj
                )
                .then(() => {
                  fileObj.uploading = false;
                  fileObj.success = true;
                  vData.SubmitStatus.Value = vData.SubmitStatus.Options.OK;
                })
                .catch(() => {
                  fileObj.error = true;
                  fileObj.errorMessage =
                    "File is too large, please reduce your file's size and try again.";
                  vData.SubmitStatus.Value = vData.SubmitStatus.Options.ERROR;
                });
            }
          }
        }
        await this.sleep(3000);

        this.finishApplication(uploadData);
      } else {
        vData.SubmitStatus.Value = vData.SubmitStatus.Options.ERROR;
      }
    },
    async finishApplication(uploadData) {
      var vData = this;
      if (
        !uploadData.some(i => i.uploading) &&
        vData.SubmitStatus.Value == vData.SubmitStatus.Options.OK
      ) {
        vData.$router.push({
          name: "FileUploadSuccess",
          params: { profileId: vData.profileId }
        });
      }
    },
    sleep(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    }
    // async submitFiles() {
    //   var FileEntries = [];

    //   // get the keys to the files to know the number of files to be uploaded
    //   var numberOfFiles = Object.keys(this.RifFiles);

    //   // Remove all but the last file and place all other files in the FileEntries array
    //   for (var i = 0; i < numberOfFiles.length - 1; i++) {
    //     var currentFile = this.RifFiles.shift();
    //     FileEntries.push(currentFile);
    //   }

    //   // Upload the last file
    //   await this.handleFileUpload(this.RifFiles);

    //   // Replace the file in Landlord files with the other files that need to be uploaded and upload them
    //   for (var k = 0; k < FileEntries.length; k++) {
    //     this.RifFiles.splice(0, 1, FileEntries[k]);
    //     await this.handleFileUpload(this.RifFiles);
    //   }

    //   //await this.handleFileUpload(this.RentLedger);
    //   //await this.handleFileUpload(this.ProofOfDeliveryDocument);

    //   await this.sleep(3000);

    //   this.finishApplication();
    // }
  }
};
</script>

<style lang="scss" scoped>
.columns,
.column,
.content div {
  margin-bottom: 0;
}
.address-label {
  padding-left: 5px;
}
.is-flyers-orange {
  color: hsl(35, 100%, 49%);
}

.is-phanatic-green {
  color: hsl(121.6, 38.6%, 37.1%);
}

.is-love-park-red {
  color: hsl(14.1, 100%, 40%);
}
</style>
