import DataObjectField from "@/utilities/classes/data-object-field";

const buildInvoiceUploadSubmissionDataObject = (
  profileId,
  fileName,
  base64File,
  contractorName,
  paymentDate,
  amountPaid,
  description,
  unitNumber,
  address,
  email
) => {
  const dataObject = {
    qdbapi: {
      udata: "RIF_FILE_UPLOAD",
      apptoken: "d8phrhgbq4mmfwc3rkhnddciskd3",
      field: [
        new DataObjectField(contractorName, 51),
        new DataObjectField(paymentDate, 52),
        new DataObjectField(amountPaid, 55),
        new DataObjectField(description, 53),
        new DataObjectField("Payment invoices and receipts", 6),
        new DataObjectField(address + " #" + unitNumber, 27),
        new DataObjectField(email, 9),
        {
          _fid: "19",
          __text: profileId
        },
        {
          _fid: "7",
          _filename: fileName,
          __text: base64File
        }
      ]
    }
  };

  return dataObject;
};

export default buildInvoiceUploadSubmissionDataObject;
