<template>
  <div class="container">
    <div class="columns is-centered">
      <div class="column">
        <ValidationObserver v-slot="{ errors }" tag="div">
          <input-form
            ref="form"
            :errors="errors"
            name="InvoiceUploadsForm"
            @submit.prevent="handleFileUpload"
          >
            <div class="content">
              <br />
              <h2>Proof of Payment</h2>
              <h3>Upload Required Documents</h3>
            </div>
            <callout type="warning">
              <div>
                RIF requires proof of payment for all repairs completed with
                loan funds. Please provide copies of itemized receipts for all
                repairs. Cash receipts are not acceptable. We recommend
                combining multiple payments to the same vendor into one document
                to help us process your documents more efficiently.
              </div>
            </callout>
            <callout type="info">
              <div>
                The file upload accepts images and PDFs, with a maximum file
                size of 4.5mb. You may select multiple documents at a time.
              </div>
            </callout>
            <callout type="danger">
              <strong>
                IMPORTANT -- Please follow these steps to upload documents
                successfully:
              </strong>
              <ol style="margin-left: 3%;">
                <li>
                  Select the files you wish to upload using the blue button
                  below.
                </li>
                <!--<li>The "Finish" button will appear in the bottom right corner of this page when documents have been selected for required fields.</li>-->
                <li>
                  Once all files have been Selected, Click the Finish button to
                  upload them.
                </li>
                <li>
                  Do not hit the back button. Wait for the green message that
                  all documents have uploaded before closing this page.
                </li>
              </ol>
            </callout>
            <div class="columns">
              <div class="column">
                <vee-textbox
                  v-model="Name"
                  name="input-name"
                  placeholder="Name"
                  rules="required"
                />
              </div>
              <div class="column">
                <vee-textbox
                  v-model="Email"
                  name="input-email"
                  placeholder="Email"
                  rules="required|email"
                />
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <vee-textbox
                  v-model="Address"
                  name="input-address"
                  placeholder="Address"
                  rules="required"
                />
              </div>
              <div class="column">
                <vee-textbox
                  v-model="UnitNumber"
                  name="input-unit-number"
                  placeholder="Unit Number"
                  rules="numeric"
                />
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <vee-dropdown
                  v-model="FileEntry.NumberOfFileEntriesSelected"
                  label="How many files are you submitting?"
                  :options="FileEntry.fileEntryList.map(i => i.id.toString())"
                  placeholder="How many files are you submitting?"
                  required
                  rules="required"
                  error="This field is required"
                  mode="eager"
                  @change="refresh(FileEntry.NumberOfFileEntriesSelected)"
                />
              </div>
            </div>
            <div
              v-for="fileEntryObj in FileEntry.fileEntryList"
              :key="fileEntryObj.id"
              :class="`entry-information-${fileEntryObj.id}`"
            >
              <div
                v-if="
                  FileEntry.NumberOfFileEntriesSelected !== null &&
                    FileEntry.NumberOfFileEntriesSelected >= fileEntryObj.id
                "
              >
                <div class="field-container file-uploads">
                  <h3><b>File Uploads</b></h3>
                </div>
                <h5>Additional Files:</h5>
                <ul id="upload-list-landlord-documents">
                  <li
                    v-for="currentFile in fileEntryObj.file"
                    :key="currentFile.id"
                  >
                    <span>{{ currentFile.name }} - </span>
                    <span v-if="currentFile.error" class="is-love-park-red">
                      Something went wrong - {{ currentFile.errorMessage }}
                    </span>

                    <span
                      v-else-if="currentFile.success"
                      class="is-phanatic-green"
                    >
                      Upload Successful. Please select another document.
                    </span>
                    <span
                      v-else-if="currentFile.uploading"
                      class="is-flyers-orange"
                    >
                      Uploading Document...
                    </span>
                  </li>
                </ul>
                <file-upload
                  ref="upload"
                  v-model="fileEntryObj.file"
                  :input-id="`file-upload-landlord-file-${fileEntryObj.id}`"
                  extensions="gif,jpg,jpeg,png,webp,pdf"
                  accept="image/png,image/gif,image/jpeg,image/webp,application/pdf"
                  :size="1024 * 1024 * 4.5"
                  @input-filter="inputFilter"
                >
                  <button class="button is-primary" style="margin-bottom: 1%;">
                    <i class="fa fa-plus" />
                    Select Files to Upload
                  </button>
                </file-upload>
                <div class="columns">
                  <div class="column">
                    <vee-textbox
                      v-model="fileEntryObj.ContractorName"
                      name="input-contractor-name"
                      placeholder="Contractor Name"
                      required
                      error="This field is required"
                      rules="required"
                    />
                  </div>
                  <div class="column">
                    <vee-textbox
                      v-model="fileEntryObj.PaymentDate"
                      :name="`input-payment-date`"
                      placeholder="Payment Date"
                      type="date"
                      required
                      error="This field is required"
                      rules="required"
                    />
                  </div>
                </div>
                <div class="columns">
                  <div class="column">
                    <vee-textbox
                      v-model="fileEntryObj.AmountPaid"
                      name="input-amount-paid"
                      placeholder="Amount Paid"
                      required
                      rules="required|doubleCurrency"
                      error="This field is required"
                    />
                  </div>
                  <div class="column">
                    <vee-textbox
                      v-model="fileEntryObj.Description"
                      name="input-description"
                      placeholder="Description of repairs and materials invoiced (optional)"
                    />
                  </div>
                </div>
                <!-- <div class="columns">
                <div class="column">
                  <vee-textbox
                    v-model="fileEntryObj.ActualCost"
                    name="input-actual-cost"
                    placeholder="Actual Invoiced Cost"
                    rules="required|doubleCurrency"
                  />
                </div>
                <div class="column">
                  <vee-textbox
                    v-model="fileEntryObj.Notes"
                    name="input-notes"
                    placeholder="Notes/Comments"
                    rules="required"
                  />
                </div>
              </div> -->
              </div>
            </div>
            <div class="actions columns">
              <!--Required criteria for above div: v-if="(ProofOfDeliveryDocument.length != 0 && RentLedger.length != 0)"-->
              <div class="column is-2 is-offset-20" style="margin-top:2%">
                <button
                  class="button is-primary"
                  @click="
                    handleFileUpload(
                      FileEntry.fileEntryList,
                      FileEntry.NumberOfFileEntriesSelected
                    )
                  "
                >
                  Submit proof of payment
                </button>
              </div>
            </div>
          </input-form>
        </ValidationObserver>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

import FileUpload from "vue-upload-component";
import options from "@/utilities/options";

import buildInvoiceUploadSubmissionDataObject from "@/utilities/invoice-upload-data-object";
import FileEntry from "@/utilities/classes/InvoiceEntry";
//import buildLandlordDataFetchObject from "@/utilities/data-objects/landlord-data-fetch-object";
import { dataObjectToXml, resizeImage, getBase64 } from "@/utilities/functions";

export default {
  name: "Files",
  components: { FileUpload },
  mixins: [options],
  props: {
    profileId: { default: null, type: String }
  },
  data() {
    return {
      Name: null,
      Email: null,
      Address: null,
      UnitNumber: null,
      FileEntry: {
        fileEntryList: [],
        NumberOfFileEntriesSelected: null,
        file: [],
        ContractorName: null,
        PaymentDate: null,
        AmountPaid: null,
        Description: null
      }
    };
  },
  created() {
    var vData = this;
    for (let i = 0; i < 10; i++) {
      const fileEntryObj = new FileEntry(i + 1);
      vData.FileEntry.fileEntryList.push(fileEntryObj);
    }
  },
  methods: {
    async finishApplication(uploadData) {
      var vData = this;
      if (!uploadData.some(i => i.uploading)) {
        vData.$router.push({
          name: "InvoiceUploadSuccess"
        });
      }
    },
    inputFilter(newFile, oldFile, prevent) {
      if (newFile && !oldFile) {
        // Before adding a file
        // Filter system files or hide files
        if (/(\/|^)(Thumbs\.db|desktop\.ini|\..+)$/.test(newFile.name)) {
          return prevent();
        }
        // Filter php html js file
        if (/\.(php5?|html?|jsx?|exe?)$/i.test(newFile.name)) {
          return prevent();
        }
      }
    },
    refresh(NumberOfFiles) {
      if (NumberOfFiles == 1) {
        window.history.replaceState(
          null,
          null,
          "/?file=#/InvoiceUpload/" + this.profileId
        );
      }
      if (NumberOfFiles == 2) {
        window.history.replaceState(
          null,
          null,
          "/?file=&file=#/InvoiceUpload/" + this.profileId
        );
      }
      if (NumberOfFiles == 3) {
        window.history.replaceState(
          null,
          null,
          "/?file=&file=&file=#/InvoiceUpload/" + this.profileId
        );
      }
      if (NumberOfFiles == 4) {
        window.history.replaceState(
          null,
          null,
          "/?file=&file=&file=&file=#/InvoiceUpload/" + this.profileId
        );
      }
      if (NumberOfFiles == 5) {
        window.history.replaceState(
          null,
          null,
          "/?file=&file=&file=&file=&file=#/InvoiceUpload/" + this.profileId
        );
      }
      if (NumberOfFiles == 6) {
        window.history.replaceState(
          null,
          null,
          "/?file=&file=&file=&file=&file=&file=#/InvoiceUpload/" +
            this.profileId
        );
      }
      if (NumberOfFiles == 7) {
        window.history.replaceState(
          null,
          null,
          "/?file=&file=&file=&file=&file=&file=&file=#/InvoiceUpload/" +
            this.profileId
        );
      }
      if (NumberOfFiles == 8) {
        window.history.replaceState(
          null,
          null,
          "/?file=&file=&file=&file=&file=&file=&file=&file=#/InvoiceUpload/" +
            this.profileId
        );
      }
      if (NumberOfFiles == 9) {
        window.history.replaceState(
          null,
          null,
          "/?file=&file=&file=&file=&file=&file=&file=&file=&file=#/InvoiceUpload/" +
            this.profileId
        );
      }
      if (NumberOfFiles == 10) {
        window.history.replaceState(
          null,
          null,
          "/?file=&file=&file=&file=&file=&file=&file=&file=&file=&file=#/InvoiceUpload/" +
            this.profileId
        );
      }
    },
    async handleFileUpload(uploadData, entryIdx) {
      var vData = this;
      const isInputValid = await vData.$refs.form.$parent.validate();
      if (isInputValid) {
        if (uploadData.length) {
          for (var i = 0; i < entryIdx; i++) {
            let FileIdx = uploadData[i].file.length - 1;
            var fileObj = uploadData[i].file[FileIdx];
            fileObj.uploading = true;
            fileObj.error = false;
            fileObj.errorMessage = "";
            let file = fileObj.file;
            if (fileObj.type.match(/image.*/)) {
              file = await resizeImage({ file: file, maxSize: 1920 });
            }
            if (file.size >= 1024 * 1024 * 4.5) {
              fileObj.error = true;
              fileObj.errorMessage =
                "File is too large, please reduce your file's size and try again.";
            } else {
              const base64File = await getBase64(fileObj.file);
              const dataObject = buildInvoiceUploadSubmissionDataObject(
                vData.profileId,
                fileObj.name,
                base64File,
                uploadData[i].ContractorName,
                uploadData[i].PaymentDate,
                uploadData[i].AmountPaid,
                uploadData[i].Description,
                vData.UnitNumber,
                vData.Address,
                vData.Email
              );
              const xmlDataObject = dataObjectToXml(dataObject);
              const requestObj = {
                table: "bs3rxqfik",
                action: "API_AddRecord",
                data: xmlDataObject
              };
              axios
                .post(
                  "https://djql8jp3sb.execute-api.us-east-1.amazonaws.com/Rental-Improvement-Fund-QuickBase-Request-Proxy",
                  requestObj
                )
                .then(() => {
                  fileObj.uploading = false;
                  fileObj.success = true;
                })
                .catch(() => {
                  fileObj.error = true;
                  fileObj.errorMessage =
                    "File is too large, please reduce your file's size and try again.";
                });
            }
          }
        }
        await this.sleep(3000);

        this.finishApplication(uploadData);
      }
    },
    sleep(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    }
    // async submitFiles() {
    //   var FileEntries = [];

    //   // get the keys to the files to know the number of files to be uploaded
    //   var numberOfFiles = Object.keys(this.RifFiles);

    //   // Remove all but the last file and place all other files in the FileEntries array
    //   for (var i = 0; i < numberOfFiles.length - 1; i++) {
    //     var currentFile = this.RifFiles.shift();
    //     FileEntries.push(currentFile);
    //   }

    //   // Upload the last file
    //   await this.handleFileUpload(this.RifFiles);

    //   // Replace the file in Landlord files with the other files that need to be uploaded and upload them
    //   for (var k = 0; k < FileEntries.length; k++) {
    //     this.RifFiles.splice(0, 1, FileEntries[k]);
    //     await this.handleFileUpload(this.RifFiles);
    //   }

    //   //await this.handleFileUpload(this.RentLedger);
    //   //await this.handleFileUpload(this.ProofOfDeliveryDocument);

    //   await this.sleep(3000);

    //   this.finishApplication();
    // }
  }
};
</script>
