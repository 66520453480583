export default class Property {
  constructor(
    _id,
    _street1,
    _street2,
    _city,
    _state,
    _zip,
    _numOfUnits,
    _ownership,
    _nameOnTitle,
    _rawAddress,
    _addressCandidateList = [],
    _addressCandidates = []
  ) {
    this.id = _id;
    this.street1 = _street1;
    this.street2 = _street2;
    this.city = "Philadelphia";
    this.state = "Pennsylvania";
    this.zip = _zip;
    this.numOfUnits = _numOfUnits;
    this.ownership = _ownership;
    this.nameOnTitle = _nameOnTitle;
    this.rawAddress = _rawAddress;
    this.addressCandidateList = _addressCandidateList;
    this.addressCandidates = _addressCandidates;
    this.AddressCandidatesLoaded = false;
    this.ShowCandidateTable = false;
  }
}
