<template>
  <div id="app">
    <app-header appTitle="Rental Improvement Fund" :branding-image="deptLogo">
      <tabs-nav slot="tabs-nav" :links="navLinks" />
      <mobile-nav slot="mobile-nav" :links="navLinks" />
    </app-header>
    <main class="container main">
      <router-view />
    </main>
    <app-footer :links="[{ href: '/', text: 'Feedback' }]" />
  </div>
</template>
<script>
import logo from "@/assets/DPD BELL logo_w.png";
export default {
  data() {
    return {
      deptLogo: {
        src: logo,
        alt: "Philadelphia Department of Planning and Development logo",
        width: "190px"
      },
      navLinks: [
        {
          href: "Apply",
          text: "Apply"
        },
        {
          href: "InformationSessions",
          text: "Information Sessions"
        },
        {
          href: "About",
          text: "About"
        },
        {
          href: "LoanDetails",
          text: "Check Application Status"
        }
        // {
        //   href: "FAQ25K-10Y",
        //   text: "25K/10Y FAQ"
        // },
        // {
        //   href: "FAQ50K-15Y",
        //   text: "50K/15Y FAQ"
        // }
      ]
    };
  },
  mounted() {
    const browser = navigator.userAgent;
    const isMobile = /mobi/i.test(browser);
    const translateNavLinkId = `google_translate_element_${
      isMobile ? "mobile" : "desktop"
    }`;

    const navId = isMobile ? "mobile-menu" : "tabs-nav";
    const navElement = document.getElementById(navId);

    const element = document.createElement("a");
    element.className = "no-js";
    element.id = translateNavLinkId;
    navElement.append(element);
  }
};
</script>
<style lang="scss">
@import "./assets/scss/main.scss";

#google_translate_element {
  max-height: 40px !important;
  background-color: transparent !important;
}
#google_translate_element > *,
#google_translate_element a,
.goog-te-menu-value > * {
  color: black !important;
  background-color: white !important;
}
.goog-te-banner-frame {
  top: 112px !important;
}
#google_translate_element a {
  padding: 0 !important;
}

main.content {
  margin-top: 145px !important;
}

@media screen and (max-width: 768px) {
  main.content {
    margin-top: 72px !important;
  }

  .goog-te-banner-frame {
    top: 70px !important;
    z-index: 5;
  }
  #google_translate_element > *,
  #google_translate_element a,
  .goog-te-menu-value > * {
    color: black !important;
    background-color: transparent !important;
  }
  #google_translate_element {
    display: table !important;
  }
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  color: #2c3e50;
}

body {
  min-height: auto !important;
}

a {
  word-break: break-word;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

#trusted-site {
  display: none;
}
</style>
