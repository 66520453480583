import DataObjectField from "@/utilities/classes/data-object-field";

const buildHelpDeskSubmissionDataObject = vData => {
  const fields = [
    new DataObjectField(vData.FirstName, 6),
    new DataObjectField(vData.LastName, 7),
    new DataObjectField(vData.EmailAddress, 8),
    new DataObjectField(vData.RequestType, 9),
    new DataObjectField(vData.Message, 10),
    new DataObjectField(vData.PhoneNumber, 11),
    new DataObjectField(vData.PreferredMethodOfContact, 12),
    new DataObjectField(vData.loanId, 26),
    new DataObjectField(vData.DeductionCheck, 23),
    new DataObjectField(vData.InspectionRequestType, 22)
  ];

  const dataObject = {
    qdbapi: {
      udata: "_DBID_HELP_DESK",
      apptoken: "d8phrhgbq4mmfwc3rkhnddciskd3",
      field: fields
    }
  };
  return dataObject;
};

export default buildHelpDeskSubmissionDataObject;
